import React from "react"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { graphql} from "gatsby";
import Layout from "../components/layout"
import SEO from "../components/seo"


const ProductDetails = data => (
  < Layout >
    <SEO title={data.data.markdownRemark.frontmatter.name} keywords={[`Kate`, `Tiến Thành`, `Việt Thắng`]} />
    <div className="container details-page">
      <div className="product-details">
        <div className="Product-Screenshot">
          {data.data.markdownRemark.frontmatter.featuredImage  ?
            <Tabs>
              {data.data.markdownRemark.frontmatter.listOfImages.map((item, index) => (
                <TabPanel key ={index}>
                  <Tab>
                    <img
                      alt={data.data.markdownRemark.frontmatter.name}
                      src={item} 
                      />
                  </Tab>
                </TabPanel>
              ))}
              <TabList>
                {data.data.markdownRemark.frontmatter.listOfImages.map((item, index) => (
                  <Tab key ={index}>
                    <img
                        alt={data.data.markdownRemark.frontmatter.name}
                        src={item} />
                  </Tab>
                ))}
              </TabList>
            </Tabs> 

            :
            <div className="no-image">No Image</div>
          }

        </div>
        <div>
          <h2>{data.data.markdownRemark.frontmatter.name}</h2>

        </div>
        <div className="row buynowinner">
          <div className="col-sm-2">
            <span className="price">Price: ${data.data.markdownRemark.frontmatter.price}</span>
          </div>
          <div className="col-sm-10 text-left">
            <a
              href="/"
              className="Product snipcart-add-item"
              data-item-id={data.data.markdownRemark.frontmatter.slug}
              data-item-price={data.data.markdownRemark.frontmatter.price}
              // data-item-image={data.data.markdownRemark.frontmatter.featuredImage && data.data.markdownRemark.frontmatter.listOfImages[0] ? `${process.env.GATSBY_FLOTIQ_BASE_URL}/image/1920x0/${data.data.product.productImage[0].id}.${data.data.product.productImage[0].extension}` : ""}              
              data-item-name={data.data.markdownRemark.frontmatter.name}
              data-item-url={`/`}
            >
              <i className="fas fa-tags" />
              Buy Now
            </a>
          </div>
        </div>
        <div
          dangerouslySetInnerHTML={{
            __html: data.data.markdownRemark.html
          }}
        />
      </div>
    </div>
    
     {/* <div>
       {data.data.markdownRemark.frontmatter.slug}
     </div> */}
  </Layout >
)

export const query = graphql`
  query productBySlug($slug: String!) {
    markdownRemark(frontmatter: {slug: {eq: $slug}}) {
      id,
      frontmatter {
        date
        featuredImage
        name
        slug
        price
        title
        listOfImages
      },
      html
    }
  }
`
export default ProductDetails